<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="conversations !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >
        <b-row>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
              class="m-2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="search"
                  class="d-inline-block mr-1"
                  placeholder="جستجو..."
              />
            </div>
          </b-col>

          <b-col
              cols="12"
              md="2"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>نمایش</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>تعداد</label>
          </b-col>

          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>نمایش بر اساس وضعیت چت :</label>
            <v-select
                v-model="hasSeen"
                :options="hasSeenOptions"
                :reduce="val => val.value"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50 w-50"
            />
          </b-col>
        </b-row>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="conversations"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(userId)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/media/gallery/profile/${data.item.userPhoto}`"
                    :variant="`success`"
                    :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.userName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <template #cell(edit)="data">

            <router-link :to="{ name: 'apps-chats-id', params: { id: data.item.userId } }" class="cursor-pointer d-flex flex-row w-25">
              <feather-icon icon="EditIcon" size="20"/>
            </router-link>

          </template>

          <template #cell(unReadCount)="data">

            <b-badge
                v-if="data.item.unReadCount !== 0"
                pill
                class="text-capitalize"
                :variant="`primary`"
            >
              {{data.item.unReadCount}}
            </b-badge>
            <span v-else>{{data.item.unReadCount}}</span>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <!-- modal vertical center -->
    <b-modal
        v-if="showDeleteModal"
        id="modal-center"
        centered
        title="ویرایش خصوصیت"
        ok-title="حذف"
        cansel-title="انصراف"
        @cancel="showDeleteModal = !showDeleteModal"
        @ok="deleteTicket(deleteItem)"

    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal
} from 'bootstrap-vue'
import {MessageGetMenuRequest} from "@/libs/Api/Message";
import {MessageDeliverRequest} from "@/libs/Api/Message";
import { mapGetters } from "vuex";
import vSelect from 'vue-select'
export default {
  name: "Chats",
  title: 'چت ها - پنل ادمین مکس ',
  data(){
    return{
      conversations:null,
      tickets:null,
      overlay:false,
      totalCount:null,
      showDeleteModal:false,
      deleteItem : null,
      currentPage:1,
      perPage:50,
      defaultGetData:{
        pageNumber:1,
        count:10,
      },
      perPageOptions:[10, 20, 30 , 40 , 50],
      hasSeenOptions:[
        {label:'همه',value:''},
        {label:'خوانده شده',value:true},
        {label:'خوانده نشده',value:false},
      ],
      search:'',
      hasSeen:'',
      myTableColumns : [
        { key: 'userId',label:'اطلاعات کاربر'},
        { key: 'unReadCount',label:'پیام های خوانده نشده'},
        // { key: 'userName',label:'نام کاربر'},
        { key: 'edit',label:'نمایش'},
      ],
    }
  },
  computed:{
    ...mapGetters(["SocketId"]),
  },
  watch:{
    SocketId: function (val, oldVal) {
      this.getMenu(this.currentPage,this.perPage,this.search,this.hasSeen);
      this.$store.commit("SetDefaultSocketId");
    },
    search:function (nv,ov){
      this.getMenu(this.currentPage,this.perPage,nv,this.hasSeen)
    },
    currentPage:function (nv,ov){
      this.getMenu(nv,this.perPage,this.search,this.hasSeen)
    },
    perPage:function (nv,ov){
      this.getMenu(this.currentPage,nv,this.search,this.hasSeen)
    },
    hasSeen:function (nv,ov){
      this.getMenu(this.currentPage,this.perPage,this.search,nv)
    },
  },
  async created(){
    await this.getMenu(this.currentPage,this.perPage,this.search,this.hasSeen);
  },
  methods:{
    async getMenu(page,count,search,hasSeen){
      let _this = this;
      _this.overlay = true;

      let data = {
        page:page,
        size:count,
        searchCommand:search,
        seen:hasSeen
      }

      let messageGetMenuRequest = new MessageGetMenuRequest(_this);
      messageGetMenuRequest.setParams(data)
      await messageGetMenuRequest.fetch(function (content){
        _this.overlay = false;
        _this.conversations = content.data.filter(_this.onlyUnique);
        _this.totalCount = content.totalCount;
        _this.deliverMessages();
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async deliverMessages(){
      let _this = this;

      let messageDeliverRequest = new MessageDeliverRequest(_this);
      await messageDeliverRequest.fetch(function (content){

      },function (error){
        console.log(error)
      })
    },
    showModal(param){
      let _this = this;

      _this.showDeleteModal = true;
      _this.deleteItem = param
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>